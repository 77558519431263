import React from 'react';
import { Field, CccisdInput, CccisdToggle } from 'cccisd-formik';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import { startCase } from 'lodash';

export const initialValues = {
    cover: {
        show: true,
        title: 'Building Healing Systems',
        subtitle: 'Trauma-Informed Organizational Assessment',
        detailsLine1: 'Assessment items adapted with permission from the National Center for Child Traumatic Stress.',
        detailsLine2:
            'Halladay Goldman, J., Purbeck Trunzo, C., and Agosti, J. (2019). NCTSN Trauma-Informed Organizational',
        detailsLine3: 'Assessment. Los Angeles, CA, and Durham, NC: National Center for Child Traumatic Stress,',
        pageNumber: '0',
    },
    page1: {
        show: true,
        title: 'Understanding this Summary',
        paragraph1:
            'This report is generated from information your organization provided. The composite score is the average of the rating for every item within the domain.',
        paragraph2:
            'Domains where contributors reported the practices within the domain do not exist yet within the organization and the overall mean falls between 1.0–1.9. Scores in this range indicate that an organization is functioning in a way that is trauma unaware or, at best, where some practices may align with a "trauma-aware" approach (i.e., the organization and workforce may have a basic awareness of trauma and its impacts).',
        paragraph3: `Domains where contributors reported the practices within the domain exist and they are carried out periodically but are not standard within the organization. The overall mean falls between 2.0–3.9. Scores in this category indicate that the organization's practices range from a "trauma-sensitive" approach to a "trauma-responsive" approach.`,
        paragraph4:
            'Domains where contributors reported the practices within the domain are part of the everyday practice, and seen in training and protocols. The overall mean falls between 4.0–5.0. Scores in this range indicate the organization is functioning in ways most aligned with a "trauma-informed" approach, where the organization has fully integrated all core trauma-informed principles throughout all levels of its infrastructure. The organizational practices are also healing centered, such that they are culturally responsive and foster equitable culture and care.',
        pageNumber: '1',
    },
    page2: {
        show: true,
        title: 'Understanding this Summary',
        subheader: 'Building Healing Systems Domains',
        pageNumber: '2',
    },
    page3: {
        show: true,
        title: 'Trauma-Informed Screening',
        pageNumber: '3',
    },
    page4: {
        show: true,

        question1:
            'The organization ensures that all staff who conduct screening demonstrate skills such as how to build safety and trust, how to recognize reminders and warning signs, and how to help regulate feelings.',
        question2:
            'The screening process is sensitive to race/ ethnicity, gender identity, sexual orientation, culture, language, age differences, and abilities.',
        question3:
            'Before administering a screening, the organization ensures that people served understand the purpose, the right to consent or refuse, how the results will be used, and who has access to the results.',
        question4:
            'The organization uses non-technical language and a collaborative style to discuss and make meaning of screening results with people served.',
        question5:
            'The organization ensures that staff at all levels receive supervision to guide the screening process, communication of results, and planning.',
        question6:
            'The organization regularly monitors and evaluates trends, including differences by relevant demographics (i.e., race/ethnicity, sexual orientation, gender identity, etc.), from all their screening results to inform staff, organization, and community responses to the needs of the population served.',
        question7:
            'The organization provides timely responses to the needs identified by the screening (e.g., trauma symptoms, impairment, safety concerns).',
        question8:
            'The organization systematically screens clients for both trauma exposure (including exposure to racism, bias, and discrimination) and trauma symptoms.',
        question9:
            'The organization uses trauma screening tools that have been tested to ensure that they consistently measure what they are intended to measure for the service population.',
        question10:
            'The organization connects clients with timely trauma assessment when the trauma screening indicates the need.',
        pageNumber: '4',
    },
    page5: {
        show: true,
        title: 'Assessment, Care Planning, and Treatment',
        pageNumber: '5',
    },
    page6: {
        show: true,
        question1:
            'The organization provides regular, reflective supervision to staff to support the goals of the assessment process.',
        question2:
            'The organization ensures that assessments are conducted by professionals who have training and expertise in culturally responsive and trauma-informed evidence- based assessment.',
        question3:
            'The organization ensures that the trauma-informed assessment process incorporates information from multiple sources, including the person served, family members, extended family or kin, and other relevant service systems/providers.',
        question4:
            'Prior to the assessment, the organization ensures that the person served understands the purpose of the assessment, the right to consent or refuse, how the results will be used, and who has access to the results.',
        question5:
            'The organization periodically repeats the trauma-informed assessment to refine and revise individualized plans that reflect the progress and ever-changing needs of the person served.',
        question6:
            'The organization regularly monitors and evaluates trends and differences by relevant demographics (i.e., race/ethnicity, sexual orientation, gender identity, etc.) from all their trauma-informed assessment results to evaluate and improve its organizational effectiveness.',
        question7:
            'The organization ensures that the results of trauma- informed assessments are communicated to all relevant staff and external partners to enhance service coordination in a way that respects privacy and protects against misuse.',
        question8:
            'The organization ensures that the assessment process identifies how trauma exposure (including exposure to racism and other forms of oppression) and post-traumatic stress symptoms impact development (if applicable) and/or emotional and behavioral health symptoms.',
        question9:
            'The organization ensures that the results of assessments are used to identify and select trauma-specific services and treatments for clients.',
        question10:
            'The organization connects clients with trauma-specific services and treatments that are timely, evidence-based or evidence-informed, culturally responsive, and individualized.',
        pageNumber: '6',
    },
    page7: {
        show: true,
        title: 'Workforce Development',
        pageNumber: '7',
    },
    page8: {
        show: true,
        question1:
            'During the interview process, the organization assesses knowledge, understanding, and attitudes of prospective staff related to trauma-informed and healing-centered practices.',
        question2:
            'The organization ensures all staff, prior to beginning work, receive training and education on the impact of trauma on people served.',
        question3:
            'The organization ensures that all staff demonstrate the skills needed to serve people who have experienced trauma, such as building safety and trust, limiting trauma reminders, managing stress, regulating affect, and empowering clients.',
        question4:
            'The organization ensures that all staff receive regular training on the impact of trauma on individuals and communities, including those impacted by historical trauma, racism, and other forms of oppression.',
        question5:
            'The organization engages experts to provide consultation and training related to antiracism/anti-oppression, cultural responsiveness, equity, and inclusion.',
        question6:
            'The organization provides staff with access to consultation from mental health specialists with expertise in trauma-informed and healing-centered supports.',
        question7:
            'The organization provides all staff with regular supervision from a supervisor trained in understanding the impact of trauma on individuals and communities.',
        question8: `The organization's policies, practices, and physical environment demonstrate a commitment to the staff's safety and emotional well-being.`,
        question9:
            'The organization supports staff retention by fostering workplace culture that enhances strong relationships and ways to find meaning in their work.',
        question10:
            'The organization develops and promotes leaders who prioritize trauma sensitivity and healing-centered practices and encourage others to do the same.',
        question11:
            'The organization builds leadership from a diverse pool of candidates within the organization by creating equitable opportunities for career progression.',
        question12:
            'The organization regularly makes policy changes based on disaggregated feedback from staff at all levels about: training needs, supervision, culture, and climate.',
        question13:
            'The organization offers programming and innovations designed by representatives of the identities and cultures of the service population.',
        pageNumber: '8',
    },
    page9: {
        show: true,
        title: 'Strengthening Protective Factors',
        pageNumber: '9',
    },
    page10: {
        show: true,
        question1:
            'The organization provides information about resources to support the basic needs of the people served (e.g., food, health care, shelter, safety, transportation, connection to community healing practice) to reduce stress.',
        question2:
            'The organization uses culturally relevant strategies, activities, and tools that help strengthen safety and trust in professional supervisory relationships, caregiver and family relationships, and community relationships, as relevant to the individual.',
        question3:
            'The organization engages family members (e.g., caregivers, siblings, elders, kin, etc.), as defined by the people served, when appropriate, to strengthen their relationships and support for one another.',
        question4: `The organization conducts an assessment of clients' strengths and protective factors, including factors such as cultural values, faith/spiritual beliefs, social connectedness, and cultural or traditional healing practices.`,
        question5:
            'The organization works with clients (I.e., adults, youth, and families) to build and engage a caring support network to foster client well-being.',
        question6:
            'The organization enhances skill-building in clients that increases culturally relevant and identity-affirming social and emotional skills.',
        question7:
            'The organization supports caregivers and families with building skills for managing stress and fostering healing.',
        question8:
            "The organization builds upon the strengths of families' culture, faith, social connectedness, and history to strengthen protective factors.",
        question9: `The organization provides opportunities for peer support with clients.`,
        pageNumber: '10',
    },
    page11: {
        show: true,
        title: 'Addressing the Impact of Trauma on Families',
        pageNumber: '11',
    },
    page12: {
        show: true,
        question1:
            'Staff are trained on the impact that public service systems (e.g., mental health, child welfare, transportation) can have on traumatizing or re-traumatizing caregivers/families.',
        question2:
            'The organization tailors its physical environment to promote well-being, recognizing the traumatizing or re-traumatizing impact that environment can have on caregivers/families.',
        question3:
            'The organization tailors its services to the cultural values and history of the people served, recognizing that identity and culture can impact service needs.',
        question4: `The organization provides staff with training and education about adult behavioral health, adult trauma-focused treatment, and how these issues relate to family relationships and the care of children.`,
        question5:
            'The organization routinely provides opportunities for caregivers/families to be educated about the impact that ACEs and trauma exposure (including exposure to racism and other forms of oppression) may have on adult well-being and family dynamics, including parenting practices.',
        question6:
            'The organization routinely invites caregivers/families to share about their own trauma histories and healing practices to support well-being for caregivers/families.',
        question7:
            'The organization provides appropriate opportunities or referrals for caregivers/families to complete a trauma-informed behavioral health assessment, as indicated by family trauma history.',
        question8:
            'The organization identifies which caregivers/families may benefit from trauma-focused treatment as part of their general mental health treatment.',
        question9: `The organization refers caregivers/families to accessible trauma-focused treatment and/or healing- centered community resources when needed.`,
        pageNumber: '12',
    },
    page13: {
        show: true,
        title: 'Continuity of Care & Cross-Systems Collaboration',
        pageNumber: '13',
    },
    page14: {
        show: true,
        question1:
            'The organization ensures that the trauma-informed care planning process incorporates information from multiple sources including the person being served, their caregivers, extended family or kin, school, and other relevant service systems/providers.',
        question2:
            'The organization includes trauma expertise whenever the care that people being served receive is being discussed across disciplines.',
        question3:
            'The organization allocates resources to ensure staff engagement in effective cross-system collaboration.',
        question4: `The organization conducts regular meetings that include the person being served and service providers from multiple systems to coordinate the care of the people being served (e.g., multidisciplinary team meetings, individualized education planning, joint staffing).`,
        question5:
            'The organization requires and supports service providers to have regular contact with other service providers to enhance care coordination and protect against misuse of information (e.g., Memoranda of Understanding, open channels of communication between providers, built-in scheduling).',
        question6:
            'The organization uses tools and practices that promote the safe sharing of trauma-related information between systems to support coordinated care for people being served.',
        question7:
            'The organization ensures that staff from different systems and agencies participate together in training to develop a common language and understanding of roles related to trauma and trauma-informed policies and practices.',
        question8: `The organization's leadership regularly meets with leadership from other agencies and systems to maintain effective and consistent anti-racist and culturally responsive trauma-informed care.`,
        pageNumber: '14',
    },
    page15: {
        show: true,
        title: 'Addressing Secondary Traumatic Stress',
        pageNumber: '15',
    },
    page16: {
        show: true,
        question1:
            'The organization ensures that staff at all levels understand how secondary traumatic stress can impact the ability to do their job effectively.',
        question2:
            'The organization ensures that staff members at all levels receive training on understanding and managing their reactions and responses to working with individuals who have experienced trauma.',
        question3:
            'The organization facilitates strong supervisory and peer support among staff to minimize the impact of secondary traumatic stress.',
        question4: `The organization ensures that the supervision of all staff addresses how they are affected by secondary traumatic stress.`,
        question5:
            'The organization ensures that staff experiencing secondary traumatic stress symptoms are connected to trauma-focused treatment and resources.',
        question6:
            'The organization regularly solicits staff input on emotional health and well-being, safety, and secondary traumatic stress.',
        question7:
            'The organization uses what it learns from staff feedback to reduce the impact of secondary traumatic stress and promote safety and protective factors.',
        question8: `The organization uses external consultants or agencies to address secondary traumatic stress and improve its organizational practices.`,
        question9: `The organization has policies, procedures, and practices that enhance safety and actively manage risks to support staff well-being.`,
        pageNumber: '16',
    },
    page17: {
        show: true,
        title: 'Partnering with People with Lived Experience',
        pageNumber: '17',
    },
    page18: {
        show: true,
        question1:
            'The organization actively seeks out and works collaboratively with people with lived experience in the evaluation of practices and programs (development, interpretation of data, action planning).',
        question2:
            'The organization recruits and partners with people with lived experience who have various experiences and involvement with the organization, including those who have not typically been involved in decision-making.',
        question3:
            'The organization ensures that people with lived experience that they partner with are representative of the community being served in terms of race, culture, language, income, sexual orientation, gender/gender identity, religious/spiritual orientation, and education.',
        question4: `The organization's trainings are co-developed and co-delivered by staff and people with lived experience when appropriate.`,
        question5:
            'The organization includes people with lived experience on decision-making bodies and ensures that they have an equitable voice in organizational decisions (e.g., setting policy directions, developing materials, selecting programs/interventions, hiring of staff).',
        question6:
            'The organization creates opportunities for people with lived experience to engage in peer networking for support and to maintain ongoing connections. ',
        question7: `The organization's policies about involving people with lived experience allow for flexible and mutually beneficial involvement, where people with lived experience choose how they will participate.`,
        question8: `The organization supports people with lived experience who partner with the organization by compensating their time and expenses, providing child care and transportation if needed, and offering opportunities for involvement at convenient times and locations.`,
        question9: `The organization provides support to ensure that people with lived experience who contribute their expertise are prepared for meetings, events, leadership roles, and overall involvement.`,
        question10: `The organization minimizes trauma reminders and provides support or referral to support for people with lived experience who are experiencing trauma reactions related to their participation in partnerships.`,
        pageNumber: '18',
    },
    page19: {
        show: true,
        title: 'Addressing the Intersections of Culture, Race & Trauma',
        pageNumber: '19',
    },
    page20: {
        show: true,
        question1:
            'The organization honors and respects people of diverse cultures and identities, as evidenced by a physically welcoming environment that reflects the communities served.',
        question2:
            'The organization acknowledges and supports diverse family structures, a range of parenting practices, and a variety of options for accessing diverse cultural or traditional healing practices.',
        question3: `The organization invites and respects people's chosen names, pronouns, and languages.`,
        question4:
            'The organization provides initial and ongoing training and quality improvement processes to ensure that all staff work in ways that are culturally responsive, anti-racist/anti-oppressive, and equitable.',
        question5:
            'The organization ensures that the staff demographics at all levels, including management, reflect the diversity of the community being served.',
        question6:
            'The organization promotes and facilitates self-awareness, self-reflection, and an understanding of biases, especially how race and culture impact relationships, interactions, decisions, and outcomes.',
        question7:
            'The organization provides programs, services, and resources to meet the cultural and language needs and preferences of the individuals being served. ',
        question8: `The organization provides all staff with the time and resources to work with individuals in culturally and linguistically preferred ways.`,
        question9:
            'The organization connects people to services and supports in the community that are responsive to their linguistic and cultural preferences. ',
        question10:
            'The organization partners with stakeholders (e.g., multidisciplinary team members, advisory board members, trainers, consultants, peer mentors) from diverse communities in all aspects of its work, including organizational planning and decision-making processes.',
        question11: 'The organization utilizes procedures to address bias in the workplace.',
        question12:
            'The organization includes an explanation of the historical relationship between communities that have been historically marginalized (e.g., communities of color, LGBTQ+ community) and health care systems, including harm caused, in professional development activities.',
        question13:
            'The organization uses antiracist practices in interactions with other systems, such as naming and confronting racism when apparent, advocating for inclusive decision-making, and sharing power.',
        pageNumber: '20',
    },
    page21: {
        show: true,
        title: 'Quality Improvement Strategic Planning Guide',
        pageNumber: '21',
    },
    page22: {
        show: true,
        title: 'Strategic Planning Guide',
        paragraph1:
            '(For example, for the Screening domain, one goal might be that the organization will better collect and use data to identify clients who need further trauma assessment.)',
        paragraph2:
            '(For example, for the Screening domain, your goal is to better collect and use data to identify clients who need further trauma assessment. One way of measuring success might be that, by the next year, the organization is reviewing screening data quarterly to track the number of clients in need of further assessment and the number of clients connected to further assessment.)',
        Q1: 'What opportunities exist related to this goal?',
        Q1_1: 'What have been our past successes?',
        Q1_2: 'What current work is taking place related to this goal?',
        Q1_3: 'What are our available resources (leadership, infrastructure, staffing, partnerships)?',
        Q2: 'What barriers exist related to this goal?',
        Q2_1: 'What would prevent us from moving forward with this goal?',
        Q2_2: 'What would we need to overcome this/these barrier(s)?',
        Q2_3: 'What are our available resources (leadership, infrastructure, staffing, partnerships)?',
        Q3: 'How does this goal foster trauma-informed, resilience-oriented, and equitable care and culture within the organization?',
        pageNumber: '22',
    },
    page23: {
        show: true,
        pageNumber: '23',
    },
    page24: {
        show: true,
        title: 'Strategic Planning Guide',
        pageNumber: '24',
    },
    page25: {
        show: true,
        pageNumber: '25',
    },
};

const Component = () => {
    let builderBlocks = [];
    for (const valueKey of Object.keys(initialValues)) {
        if (Object.hasOwnProperty.call(initialValues, valueKey)) {
            const dict = initialValues[valueKey];
            let fields = [];

            for (const key of Object.keys(dict)) {
                if (key === 'show') {
                    fields.push(
                        <Field
                            name={valueKey + '.' + key}
                            component={CccisdToggle}
                            label={`Show ${startCase(valueKey)}?`}
                        />
                    );
                    continue;
                }

                fields.push(
                    <Field
                        name={valueKey + '.' + key}
                        component={CccisdInput}
                        label={key.includes('_') ? key.replace('_', '.') : startCase(key)}
                    />
                );
            }
            builderBlocks.push(
                <BuilderBlock title={startCase(valueKey)} key={valueKey}>
                    {fields}
                </BuilderBlock>
            );
        }
    }

    return <>{builderBlocks}</>;
};

export default reportTemplateBuilder({})(Component);
