import React from 'react';
import { Route } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import { AdminRoutes, Manage as AppdefManage } from 'cccisd-laravel-appdefs';
import { ResourceCenter, ResourceManager } from 'cccisd-laravel-resources';
import AuthRedirect from 'bundle-loader?lazy!./components/AuthRedirect';

// Layouts
import MainLayout from './layouts/MainLayout';
import QuestLayout from './layouts/QuestLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from 'bundle-loader?lazy!./pages/Welcome'; // Include it into main bundle
import MyWellbeing from 'bundle-loader?lazy!./pages/MyWellbeing';
import Overview from 'bundle-loader?lazy!./pages/Overview';
import SignUp from 'bundle-loader?lazy!./pages/SignUp';
import SiteContent from 'bundle-loader?lazy!./pages/SiteContent';
import ViewData from 'bundle-loader?lazy!./components/ViewData';
import RolePicker from 'bundle-loader?lazy!./pages/RolePicker';
import OrgAssessment from 'bundle-loader?lazy!./pages/OrgAssessment';
import DeploymentRedirect from './pages/DeploymentRedirect';

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Bind MainLayout by default
const AppRoute = defaultProps({ layout: MainLayout })(Route);

const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);
const MainLayoutSecondaryNav = defaultProps({ showSecondaryNav: true })(MainLayout);
const MainLayoutFluidSecondaryNav = defaultProps({
    className: 'container-fluid',
    showSecondaryNav: true,
})(MainLayout);

let Fortress = window.cccisd.fortress;
let AppDefinitions = window.cccisd.appDefs;

const componentMatch = {
    AppdefManage,
    Welcome,
    MyWellbeing,
    Overview,
    ResourceCenter,
    ResourceManager,
    SignUp,
    SiteContent,
    ViewData,
    RolePicker,
    OrgAssessment,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
    MainLayoutSecondaryNav,
    MainLayoutFluidSecondaryNav,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = componentMatch[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={
                        'layout' in route && layoutMatch[route.layout] ? layoutMatch[route.layout] : MainLayoutFluid
                    }
                />
            );
        })}
        {Fortress.auth() && <AppRoute path="/" component={AuthRedirect} exact />}
        <AppRoute path="/welcome" component={Welcome} />
        <AppRoute path="/account/login" component={Welcome} />
        {Fortress.hasRole('uberadmin') && (
            <AppRoute path="/resources/admin" component={ResourceManager} layout={MainLayoutFluid} exact />
        )}

        {Fortress.auth() && <AppRoute path="/rolePicker" component={RolePicker} />}
        <AppRoute path={['/quest/preview', '/d', '/survey']} component={DeploymentRedirect} layout={QuestLayout} />

        {/* Laravel packages routes */}
        {Fortress.auth() && Fortress.hasRole('uberadmin') && AdminRoutes(MainLayoutFluid)}

        <AppRoute
            path="/account"
            layout={null}
            component={Nexus}
            componentProps={{ defaultLayout: MainLayout, registrationSurveyLayout: QuestLayout }}
        />
        {Fortress.auth() && <AppRoute path="/overview" component={Overview} layout={MainLayout} />}
        {Fortress.auth() && <AppRoute path="/siteContent" component={SiteContent} layout={MainLayout} />}

        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {/* Not found page */}
        <AppRoute component={AuthRedirect} componentProps={{ isComponentOnly: true }} />
    </Switch>
);
