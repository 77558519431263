import React from 'react';
import { Field, CccisdToggle, CccisdSelect, CccisdColorPicker } from 'cccisd-formik';
import Tooltip from 'cccisd-tooltip';
import IconInfo from 'cccisd-icons/info2';
import settingsWrapper from 'cccisd-laravel-assignment/dist/helpers/settingsWrapper';
import style from './style.css';

export const initialValues = {
    mustTakeInOrder: true,
    highlighting: '',
    requiredHighlightColor: 'dangerColor',
    optionalHighlightColor: '#777777',
};

const Settings = ({ questSettings }) => (
    <>
        <Field
            name="mustTakeInOrder"
            component={CccisdToggle}
            label="User must take surveys in order"
        />
        <Field name="highlighting">
            {fieldProps => {
                // avoids React warning for passing `value` prop of null
                if (!fieldProps.field.value) {
                    fieldProps.field.value = '';
                }

                return (
                    <CccisdSelect
                        {...fieldProps}
                        label={
                            <span className="text-primary">
                                Highlighting{' '}
                                <Tooltip
                                    title={
                                        <div>
                                            <ul className={style.highlightOptions}>
                                                <li>
                                                    <b>Required:</b> Highlights all lessons that
                                                    have the &quot;Required&quot; setting toggled
                                                    ON. Modules will be highlighted if they contain
                                                    one at least one required survey.
                                                </li>
                                                <li>
                                                    <b>Optional:</b> Highlights all lessons that
                                                    have the &quot;Required&quot; setting toggled
                                                    OFF. Modules will be highlighted if they contain
                                                    exclusively non-required surveys.
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                    placement="top"
                                >
                                    <IconInfo />
                                </Tooltip>
                            </span>
                        }
                        options={[
                            {
                                value: '',
                                label: '-- None --',
                            },
                            {
                                value: 'required',
                                label: 'Required',
                            },
                            {
                                value: 'optional',
                                label: 'Optional',
                            },
                        ]}
                    />
                );
            }}
        </Field>
        {questSettings.navigationSettings.highlighting === 'required' && (
            <Field
                name="requiredHighlightColor"
                component={CccisdColorPicker}
                label="Required Highlight Color"
            />
        )}
        {questSettings.navigationSettings.highlighting === 'optional' && (
            <Field
                name="optionalHighlightColor"
                component={CccisdColorPicker}
                label="Optional Highlight Color"
            />
        )}
    </>
);

export default settingsWrapper({ initialValues })(Settings);
