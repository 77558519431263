import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';

export default class Component extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        showPolicyLinks: PropTypes.bool,
    };

    static defaultProps = {
        className: 'container',
        showPolicyLinks: true,
    };

    render() {
        return (
            <div className={style.footer} id="layout_footer">
                <div className={style.footerLower}>
                    <div className="container">
                        <div className={style.wrapper}>
                            <div>
                                Website and software developed by{' '}
                                <span className={style.link}>
                                    <a href="//www.3cisd.com/" rel="noopener noreferrer" target="_blank">
                                        3C Institute.
                                    </a>
                                </span>
                                <br />© {new Date().getFullYear()} Building Healing Behavioral Health Systems.
                            </div>
                            {this.props.showPolicyLinks && (
                                <div className={style.links}>
                                    <a href="//www.3cisd.com/terms-of-use" rel="noopener noreferrer" target="_blank">
                                        Terms of Use
                                    </a>
                                    <a href="//www.3cisd.com/privacy-policy" rel="noopener noreferrer" target="_blank">
                                        Privacy Policy
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
