import React from 'react';
import { Report } from 'cccisd-laravel-report';
import OrgReport from '../Pages/Report/Player';

const Component = props => {
    let isInEditor = window.location.href.includes('/appdefs/');
    return (
        <div style={{ width: '840px', margin: '0 auto' }}>
            <Report>
                <OrgReport {...props} isInEditor={isInEditor} />
            </Report>
        </div>
    );
};

export default Component;
