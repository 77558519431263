import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import icon1 from '../../../assets/icons/Logo_Dark Green White Text.svg';
import { initialValues } from '../../../Builder';
import orgAssessmentMetricsQuery from './orgAssessmentMetrics.graphql';
import individualAssessmentMetricsQuery from './individualAssessmentMetrics.graphql';
import { client } from 'cccisd-apollo';
import developerTags from '../../../../../../../../../../storage/app/appdefs/definitions/metrics/developer_tags_org_report.json';
import { isNaN } from 'cccisd-formik';
import IconWarning from 'cccisd-icons/notification';
import { isEmpty } from 'lodash';

const Report = props => {
    const [page3Metrics, setPage3Metrics] = useState({});
    const [page5Metrics, setPage5Metrics] = useState({});
    const [page7Metrics, setPage7Metrics] = useState({});
    const [page9Metrics, setPage9Metrics] = useState({});
    const [page11Metrics, setPage11Metrics] = useState({});
    const [page13Metrics, setPage13Metrics] = useState({});
    const [page15Metrics, setPage15Metrics] = useState({});
    const [page17Metrics, setPage17Metrics] = useState({});
    const [page19Metrics, setPage19Metrics] = useState({});
    const [hasLessThanThreeResponses, setHasLessThanThreeResponses] = useState(true);

    let customText = {};
    customText = props.settings;

    for (const key of Object.keys(initialValues)) {
        if (!(key in customText)) {
            customText[key] = initialValues[key];
        } else {
            for (const subkey of Object.keys(initialValues[key])) {
                if (!(subkey in customText[key])) {
                    customText[key][subkey] = initialValues[key][subkey];
                }
            }
        }
    }

    const page3MetricNames = developerTags.orgAssessment.page3;
    const page5MetricNames = developerTags.orgAssessment.page5;
    const page7MetricNames = developerTags.orgAssessment.page7;
    const page9MetricNames = developerTags.orgAssessment.page9;
    const page11MetricNames = developerTags.orgAssessment.page11;
    const page13MetricNames = developerTags.orgAssessment.page13;
    const page15MetricNames = developerTags.orgAssessment.page15;
    const page17MetricNames = developerTags.orgAssessment.page17;
    const page19MetricNames = developerTags.orgAssessment.page19;
    const page3Title = customText.page3.title;
    const page5Title = customText.page5.title;
    const page7Title = customText.page7.title;
    const page9Title = customText.page9.title;
    const page11Title = customText.page11.title;
    const page13Title = customText.page13.title;
    const page15Title = customText.page15.title;
    const page17Title = customText.page17.title;
    const page19Title = customText.page19.title;

    useEffect(() => {
        let page3AggregatedResponses = [
            ...page3MetricNames.map(val => {
                return [];
            }),
        ];
        let page5AggregatedResponses = [
            ...page5MetricNames.map(val => {
                return [];
            }),
        ];
        let page7AggregatedResponses = [
            ...page7MetricNames.map(val => {
                return [];
            }),
        ];
        let page9AggregatedResponses = [
            ...page9MetricNames.map(val => {
                return [];
            }),
        ];
        let page11AggregatedResponses = [
            ...page11MetricNames.map(val => {
                return [];
            }),
        ];
        let page13AggregatedResponses = [
            ...page13MetricNames.map(val => {
                return [];
            }),
        ];
        let page15AggregatedResponses = [
            ...page15MetricNames.map(val => {
                return [];
            }),
        ];
        let page17AggregatedResponses = [
            ...page17MetricNames.map(val => {
                return [];
            }),
        ];
        let page19AggregatedResponses = [
            ...page19MetricNames.map(val => {
                return [];
            }),
        ];

        const getMetricsData = async () => {
            const result = await client.query({
                query: props.isIndividual ? individualAssessmentMetricsQuery : orgAssessmentMetricsQuery,
                fetchPolicy: 'network-only',
                variables: {
                    deploymentId: props.deploymentId,
                    filter: props.filter,
                },
            });

            let orgAssessmentResults = {};
            if (!props.isIndividual) {
                orgAssessmentResults = result.data.roles.anyRoleList;
            } else {
                orgAssessmentResults = [result.data.roles.anyRole];
            }

            // only counting "completed" assessments from pawns
            const orgAssessmentCompletedResults = orgAssessmentResults.filter(individualMemberMetrics => {
                return individualMemberMetrics.assignmentProgress.completed;
            });

            if (!props.isInEditor && !props.isIndividual && orgAssessmentCompletedResults.length < 3) {
                setHasLessThanThreeResponses(true);
            } else {
                setHasLessThanThreeResponses(false);
                orgAssessmentCompletedResults.forEach(individualMemberMetrics => {
                    const individualMemberOrgAssessmentResultMetrics =
                        individualMemberMetrics.assignmentProgress.devTags;
                    for (let [key, value] of Object.entries(individualMemberOrgAssessmentResultMetrics)) {
                        if (page3MetricNames.includes(key)) {
                            page3AggregatedResponses[page3MetricNames.indexOf(key)].push(parseInt(value, 10));
                        } else if (page5MetricNames.includes(key)) {
                            page5AggregatedResponses[page5MetricNames.indexOf(key)].push(parseInt(value, 10));
                        } else if (page7MetricNames.includes(key)) {
                            page7AggregatedResponses[page7MetricNames.indexOf(key)].push(parseInt(value, 10));
                        } else if (page9MetricNames.includes(key)) {
                            page9AggregatedResponses[page9MetricNames.indexOf(key)].push(parseInt(value, 10));
                        } else if (page11MetricNames.includes(key)) {
                            page11AggregatedResponses[page11MetricNames.indexOf(key)].push(parseInt(value, 10));
                        } else if (page13MetricNames.includes(key)) {
                            page13AggregatedResponses[page13MetricNames.indexOf(key)].push(parseInt(value, 10));
                        } else if (page15MetricNames.includes(key)) {
                            page15AggregatedResponses[page15MetricNames.indexOf(key)].push(parseInt(value, 10));
                        } else if (page17MetricNames.includes(key)) {
                            page17AggregatedResponses[page17MetricNames.indexOf(key)].push(parseInt(value, 10));
                        } else if (page19MetricNames.includes(key)) {
                            page19AggregatedResponses[page19MetricNames.indexOf(key)].push(parseInt(value, 10));
                        }
                    }
                });

                setPage3Metrics({
                    metrics: removeNaNValuesInAggregatedResponses(page3AggregatedResponses),
                    title: page3Title,
                });
                setPage5Metrics({
                    metrics: removeNaNValuesInAggregatedResponses(page5AggregatedResponses),
                    title: page5Title,
                });
                setPage7Metrics({
                    metrics: removeNaNValuesInAggregatedResponses(page7AggregatedResponses),
                    title: page7Title,
                });
                setPage9Metrics({
                    metrics: removeNaNValuesInAggregatedResponses(page9AggregatedResponses),
                    title: page9Title,
                });
                setPage11Metrics({
                    metrics: removeNaNValuesInAggregatedResponses(page11AggregatedResponses),
                    title: page11Title,
                });
                setPage13Metrics({
                    metrics: removeNaNValuesInAggregatedResponses(page13AggregatedResponses),
                    title: page13Title,
                });
                setPage15Metrics({
                    metrics: removeNaNValuesInAggregatedResponses(page15AggregatedResponses),
                    title: page15Title,
                });
                setPage17Metrics({
                    metrics: removeNaNValuesInAggregatedResponses(page17AggregatedResponses),
                    title: page17Title,
                });
                setPage19Metrics({
                    metrics: removeNaNValuesInAggregatedResponses(page19AggregatedResponses),
                    title: page19Title,
                });
            }
        };

        getMetricsData();
    }, [props.deploymentId, props.filter]);

    if (hasLessThanThreeResponses && !props.isIndividual) {
        return <NotEnoughResponsesPage />;
    }

    return (
        <>
            {customText.cover.show && <Cover {...customText.cover} />}
            <>
                {customText.page1.show && <Page1 {...customText.page1} />}
                {customText.page2.show && (
                    <Page2
                        {...customText.page2}
                        allCustomText={customText}
                        metrics={[
                            page3Metrics,
                            page5Metrics,
                            page7Metrics,
                            page9Metrics,
                            page11Metrics,
                            page13Metrics,
                            page15Metrics,
                            page17Metrics,
                            page19Metrics,
                        ]}
                    />
                )}
                {customText.page3.show && !isEmpty(page3Metrics) && (
                    <Page3 {...customText.page3} metrics={page3Metrics.metrics} />
                )}
                {customText.page4.show && !isEmpty(page3Metrics) && (
                    <Page4 {...customText.page4} metrics={page3Metrics.metrics} />
                )}
                {customText.page5.show && !isEmpty(page5Metrics) && (
                    <Page5 {...customText.page5} metrics={page5Metrics.metrics} />
                )}
                {customText.page6.show && !isEmpty(page5Metrics) && (
                    <Page6 {...customText.page6} metrics={page5Metrics.metrics} />
                )}
                {customText.page7.show && !isEmpty(page7Metrics) && (
                    <Page7 {...customText.page7} metrics={page7Metrics.metrics} />
                )}
                {customText.page8.show && !isEmpty(page7Metrics) && (
                    <Page8 {...customText.page8} metrics={page7Metrics.metrics} />
                )}
                {customText.page9.show && !isEmpty(page9Metrics) && (
                    <Page9 {...customText.page9} metrics={page9Metrics.metrics} />
                )}
                {customText.page10.show && !isEmpty(page9Metrics) && (
                    <Page10 {...customText.page10} metrics={page9Metrics.metrics} />
                )}
                {customText.page11.show && !isEmpty(page11Metrics) && (
                    <Page11 {...customText.page11} metrics={page11Metrics.metrics} />
                )}
                {customText.page12.show && !isEmpty(page11Metrics) && (
                    <Page12 {...customText.page12} metrics={page11Metrics.metrics} />
                )}
                {customText.page13.show && !isEmpty(page13Metrics) && (
                    <Page13 {...customText.page13} metrics={page13Metrics.metrics} />
                )}
                {customText.page14.show && !isEmpty(page13Metrics) && (
                    <Page14 {...customText.page14} metrics={page13Metrics.metrics} />
                )}
                {customText.page15.show && !isEmpty(page15Metrics) && (
                    <Page15 {...customText.page15} metrics={page15Metrics.metrics} />
                )}
                {customText.page16.show && !isEmpty(page15Metrics) && (
                    <Page16 {...customText.page16} metrics={page15Metrics.metrics} />
                )}
                {customText.page17.show && !isEmpty(page17Metrics) && (
                    <Page17 {...customText.page17} metrics={page17Metrics.metrics} />
                )}
                {customText.page18.show && !isEmpty(page17Metrics) && (
                    <Page18 {...customText.page18} metrics={page17Metrics.metrics} />
                )}
                {customText.page19.show && !isEmpty(page19Metrics) && (
                    <Page19 {...customText.page19} metrics={page19Metrics.metrics} />
                )}
                {customText.page20.show && !isEmpty(page19Metrics) && (
                    <Page20 {...customText.page20} metrics={page19Metrics.metrics} />
                )}
                {customText.page21.show && <Page21 {...customText.page21} />}
                {customText.page22.show && <Page22 {...customText.page22} />}
                {customText.page23.show && <Page23 {...customText.page23} />}
                {customText.page24.show && <Page24 {...customText.page24} />}
                {customText.page25.show && <Page25 {...customText.page25} />}
            </>
        </>
    );
};

const Footer = ({ pageNumber }) => {
    return (
        <div className={style.footer}>
            <div className={style.footer_text}>{pageNumber}</div>
            <img src={icon1} className={style.footer_img} alt="" />
        </div>
    );
};

const CoverIcon = props => {
    return (
        <div className={style.cover_icon_container}>
            <img src={icon1} className={style.cover_icon} alt="" />
        </div>
    );
};

const TableHeader = ({ color, text }) => {
    let backgroundColor;

    switch (color) {
        case 'green':
            backgroundColor = style.table_header_green;
            break;
        case 'yellow':
            backgroundColor = style.table_header_yellow;
            break;
        case 'brown':
            backgroundColor = style.table_header_brown;
            break;
        default:
            backgroundColor = style.table_header_green;
            break;
    }

    return <div className={backgroundColor}>{text}</div>;
};

const BoldTextAndNormalText = ({ boldText = '1.0', text = 'text' }) => {
    return (
        <div className={style.center_text} style={{ paddingTop: '12px' }}>
            <b>{boldText} </b> {text}
        </div>
    );
};

const MyResponsiveBar = ({ questionsData = {} }) => {
    const lineGraphSettings = {
        theme: {
            fontSize: '17px',
        },
    };

    let questionBars = [];
    let barColor;

    for (let i = 0; i < questionsData.length; i++) {
        let questionResponses = questionsData[i];
        let averageScore = questionResponses.reduce((a, b) => {
            if (isNaN(a)) {
                a = 0;
            }
            if (isNaN(b)) {
                b = 0;
            }
            return a + b;
        }, 0);
        let lengthWithoutNanValuesOrZeroValues = 0;
        questionResponses.forEach(val => {
            if (!isNaN(val) && val !== 0) {
                lengthWithoutNanValuesOrZeroValues += 1;
            }
        });
        if (averageScore !== 0) {
            averageScore /= lengthWithoutNanValuesOrZeroValues;
        }

        if (Math.floor(averageScore) < 2) {
            barColor = '#a9613c';
        } else if (Math.floor(averageScore) < 4) {
            barColor = '#ff9822';
        } else {
            barColor = '#047a5d';
        }

        questionBars.push({
            question_number: i + 1,
            average: averageScore,
            color: barColor,
        });
    }

    return (
        <div style={{ height: '575px' }}>
            <ResponsiveBar
                theme={lineGraphSettings.theme}
                enableGridY
                data={questionBars}
                keys={['average']}
                indexBy="question_number"
                margin={{ top: 50, right: 0, bottom: 50, left: 50 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={({ data }) => data.color}
                colorBy="index"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendPosition: 'middle',
                    legendOffset: 32,
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}
                maxValue={5}
                enableLabel={false}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                }}
                legends={[]}
                isInteractive={false}
                animate={false}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={e => e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue}
            />
            <div style={{ color: '#047a5d', font: "1em 'DM Serif Display'", textAlign: 'center' }}>Item Number</div>
        </div>
    );
};

function getQuestionAverage(array) {
    let averageScore = array.reduce((a, b) => {
        if (isNaN(a)) {
            a = 0;
        }
        if (isNaN(b)) {
            b = 0;
        }
        return a + b;
    }, 0);

    // NaN = no response at all
    // 0 = "Unable to Rate" response from user
    let lengthWithoutNanAndZeroValues = 0;
    array.forEach(val => {
        if (!isNaN(val) && val !== 0) {
            lengthWithoutNanAndZeroValues += 1;
        }
    });

    if (averageScore !== 0) {
        averageScore /= lengthWithoutNanAndZeroValues;
    }
    return averageScore.toFixed(1);
}

function removeNaNValuesInAggregatedResponses(pageResponses) {
    let removedQuestionsWithoutAnyResponses = pageResponses.filter(questionResponses => {
        let questionHasResponses = false;

        for (let index = 0; index < questionResponses.length; index++) {
            const responseValue = questionResponses[index];
            if (!isNaN(responseValue)) {
                questionHasResponses = true;
                break;
            }
        }

        return questionHasResponses;
    });

    return removedQuestionsWithoutAnyResponses;
}

function getNumberOfUnableToRate(questionResponses) {
    let cnt = 0;
    questionResponses.forEach(val => {
        if (val === 0) {
            cnt += 1;
        }
    });
    return cnt;
}

function getCompositeScore(sectionQuestions, onlyScoreValue = false) {
    let averageOfAllQuestions = 0;

    let numOfQuestionsWithoutAllZeroAnswers = sectionQuestions.filter((questionAnswers, i) => {
        let sumOfAnswers = 0;
        questionAnswers.forEach(answerScore => {
            if (!isNaN(answerScore) && answerScore !== 0) {
                sumOfAnswers += answerScore;
            }
        });

        return sumOfAnswers;
    }).length;

    if (numOfQuestionsWithoutAllZeroAnswers) {
        sectionQuestions.forEach(questionResponsesArray => {
            let questionAverage = 0;
            questionResponsesArray.forEach(val => {
                if (!isNaN(val)) {
                    questionAverage += val;
                }
            });
            let responseLengthWithoutNanAndZeroValues = questionResponsesArray.filter(val => {
                if (!isNaN(val) && val !== 0) {
                    return val;
                }
                return false;
            }).length;
            if (responseLengthWithoutNanAndZeroValues === 0) {
                responseLengthWithoutNanAndZeroValues = 1;
            }
            questionAverage /= responseLengthWithoutNanAndZeroValues;
            averageOfAllQuestions += questionAverage;
        });
    } else {
        numOfQuestionsWithoutAllZeroAnswers = 1;
    }

    if (onlyScoreValue) {
        return (averageOfAllQuestions / numOfQuestionsWithoutAllZeroAnswers).toFixed(1);
    }

    return (
        <span style={{ fontSize: 'x-large' }}>
            {(averageOfAllQuestions / numOfQuestionsWithoutAllZeroAnswers).toFixed(1)}
        </span>
    );
}

function getStatTableBody(props) {
    let rows = [];

    if (isEmpty(props.metrics)) {
        let i = 0;
        for (let [key] of Object.entries(props)) {
            if (key.startsWith('question')) {
                rows.push(
                    <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{props[key]}</td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>
                );
                i += 1;
            }
        }
    } else {
        for (let i = 0; i < props.metrics.length; i++) {
            let question = `question${i + 1}`;

            rows.push(
                <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{props[question]}</td>
                    <td>{props.metrics[i].filter(val => !isNaN(val)).length}</td>
                    <td>{getQuestionAverage(props.metrics[i])}</td>
                    <td>
                        {props.metrics[i].length > 0
                            ? Math.min(
                                  ...props.metrics[i].filter(val => {
                                      return !isNaN(val);
                                  })
                              )
                            : 0}
                    </td>
                    <td>
                        {props.metrics[i].length > 0
                            ? Math.max(
                                  ...props.metrics[i].filter(val => {
                                      return !isNaN(val);
                                  })
                              )
                            : 0}
                    </td>
                    <td>{getNumberOfUnableToRate(props.metrics[i])}</td>
                </tr>
            );
        }
    }

    return (
        <>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Description</th>
                    <th>N</th>
                    <th>Avg.</th>
                    <th>Min Rating</th>
                    <th>Max Rating</th>
                    <th>
                        # of responses {'"'}Unable to rate{'"'}{' '}
                    </th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </>
    );
}

const NotEnoughResponsesPage = props => {
    return (
        <Page>
            <div className={style.page} style={{ backgroundColor: '#047a5d' }}>
                <div className="jumbotron" style={{ width: '80%', margin: 'auto auto' }}>
                    <h1>
                        <IconWarning />
                    </h1>
                    <p>Not enough respondents in this assessment to show data while maintaining anonymity.</p>
                </div>
            </div>
        </Page>
    );
};

const Cover = props => {
    return (
        <Page>
            <div className={style.page} style={{ backgroundColor: '#047a5d' }}>
                <div style={{ display: 'grid', gridAutoFlow: 'column' }}>
                    <div className={style.cover_yellow_line} />
                    <div style={{ marginLeft: '25px' }}>
                        <div className={style.cover_title}>{props.title}</div>
                        <div className={style.cover_sub_title}>{props.subtitle}</div>
                        <div
                            className={style.cover_sub_sub_title}
                            dangerouslySetInnerHTML={{ __html: props.detailsLine1 }}
                        />
                        <div className={style.cover_sub_sub_title} style={{ marginTop: '-15px' }}>
                            {props.detailsLine2}
                        </div>
                        <div className={style.cover_sub_sub_title} style={{ marginTop: '-30px' }}>
                            {props.detailsLine3}
                        </div>
                    </div>
                </div>
                <CoverIcon />
            </div>
        </Page>
    );
};

const Page1 = props => {
    return (
        <Page>
            <div className={style.page}>
                <div className={style.page_title_container}>
                    <div className={style.page_title}>{props.title}</div>
                    <div className={style.page_title_line} />
                </div>
                <div className={style.content_text}>{props.paragraph1}</div>
                <div className={style.content_text}>
                    <span className={style.color_brown} style={{ fontWeight: 'bold' }}>
                        Seeding
                    </span>
                    – {props.paragraph2}
                </div>
                <div className={style.content_text}>
                    <span className={style.color_yellow} style={{ fontWeight: 'bold' }}>
                        Budding
                    </span>{' '}
                    – {props.paragraph3}
                </div>
                <div className={style.content_text}>
                    <span className={style.color_green} style={{ fontWeight: 'bold' }}>
                        Blooming
                    </span>{' '}
                    – {props.paragraph4}
                </div>
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page2 = props => {
    let brownScores = [];
    let orangeScores = [];
    let greenScores = [];

    props.metrics.forEach((pageMetrics, i) => {
        if (!isEmpty(pageMetrics)) {
            const pageTitle = pageMetrics.title;
            pageMetrics = pageMetrics.metrics;

            if (pageMetrics.length > 0) {
                let pageMetricsCompositeScore = getCompositeScore(pageMetrics, true);
                if (Math.floor(pageMetricsCompositeScore < 2)) {
                    brownScores.push(
                        <BoldTextAndNormalText key={i} boldText={pageMetricsCompositeScore + ' '} text={pageTitle} />
                    );
                } else if (Math.floor(pageMetricsCompositeScore < 4)) {
                    orangeScores.push(
                        <BoldTextAndNormalText key={i} boldText={pageMetricsCompositeScore + ' '} text={pageTitle} />
                    );
                } else {
                    greenScores.push(
                        <BoldTextAndNormalText key={i} boldText={pageMetricsCompositeScore + ' '} text={pageTitle} />
                    );
                }
            }
        }
    });

    return (
        <Page>
            <div className={style.page}>
                <div className={style.page_title_container}>
                    <div className={style.page_title}>{props.title}</div>
                    <div className={style.page_title_line} />
                </div>
                <div className={style.page_sub_title_left}>{props.subheader}</div>
                <div className={style.ratings_category_grid} style={{ width: '99%', marginLeft: '3px' }}>
                    <TableHeader color="brown" text="Seeding" />
                    <TableHeader color="yellow" text="Budding" />
                    <TableHeader color="green" text="Blooming" />
                    <div className={style.systems_domain_grid_cell}>{brownScores}</div>
                    <div className={style.systems_domain_grid_cell}>{orangeScores}</div>
                    <div className={style.systems_domain_grid_cell}>{greenScores}</div>
                </div>
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page3 = props => {
    return (
        <Page>
            <div className={style.page}>
                <div className={style.page_title_container}>
                    <div className={style.page_title}>{props.title}</div>
                    <div className={style.page_title_line} />
                </div>
                {/* <img src={icon2} className={style.top_right_icon}></img> */}
                <div className={style.content_text}>
                    Overall Composite Score:{' '}
                    <span style={{ fontWeight: 'bold' }}>{getCompositeScore(props.metrics)}</span>
                </div>
                <table className={style.ratings_scale_table}>
                    <tbody>
                        <tr>
                            <th colSpan={6}>Building Healing Systems Rating Scale</th>
                        </tr>
                        <tr>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_without_color}>0</div>
                                    }
                                    text={<div>Unable to Rate</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_brown}>1</div>
                                    }
                                    text={<div>Never</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>2</div>
                                    }
                                    text={<div>Rarely</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>3</div>
                                    }
                                    text={<div>Sometimes</div>}
                                />
                            </td>
                            <td>
                                {' '}
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>4</div>
                                    }
                                    text={<div>Most of the Time</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>5</div>
                                    }
                                    text={<div>Always</div>}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className={style.page_sub_title_center} style={{ textAlign: 'center' }}>
                    Average Score by Question
                </div>
                <MyResponsiveBar questionsData={props.metrics} />
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page4 = props => {
    return (
        <Page>
            <div className={style.page}>
                <table className={style.results_table_page_5} style={{ margin: 'auto' }}>
                    {getStatTableBody(props)}
                </table>
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page5 = props => {
    return (
        <Page>
            <div className={style.page}>
                <div className={style.page_title_container}>
                    <div className={style.page_title}>{props.title}</div>
                    <div className={style.page_title_line} />
                </div>
                {/* <img src={icon2} className={style.top_right_icon}></img> */}
                <div className={style.content_text}>
                    Overall Composite Score:{' '}
                    <span style={{ fontWeight: 'bold' }}>{getCompositeScore(props.metrics)}</span>
                </div>
                <table className={style.ratings_scale_table}>
                    <tbody>
                        <tr>
                            <th colSpan={6}>Building Healing Systems Rating Scale</th>
                        </tr>
                        <tr>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_without_color}>0</div>
                                    }
                                    text={<div>Unable to Rate</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_brown}>1</div>
                                    }
                                    text={<div>Never</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>2</div>
                                    }
                                    text={<div>Rarely</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>3</div>
                                    }
                                    text={<div>Sometimes</div>}
                                />
                            </td>
                            <td>
                                {' '}
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>4</div>
                                    }
                                    text={<div>Most of the Time</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>5</div>
                                    }
                                    text={<div>Always</div>}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className={style.page_sub_title_center} style={{ textAlign: 'center' }}>
                    Average Score by Question
                </div>
                <MyResponsiveBar questionsData={props.metrics} />
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page6 = props => {
    return (
        <Page>
            <div className={style.page}>
                <table className={style.results_table_page_5}>{getStatTableBody(props)}</table>
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page7 = props => {
    return (
        <Page>
            <div className={style.page}>
                <div className={style.page_title_container}>
                    <div className={style.page_title}>{props.title}</div>
                    <div className={style.page_title_line} />
                </div>
                {/* <img src={icon2} className={style.top_right_icon}></img> */}
                <div className={style.content_text}>
                    Overall Composite Score:{' '}
                    <span style={{ fontWeight: 'bold' }}>{getCompositeScore(props.metrics)}</span>
                </div>
                <table className={style.ratings_scale_table}>
                    <tbody>
                        <tr>
                            <th colSpan={6}>Building Healing Systems Rating Scale</th>
                        </tr>
                        <tr>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_without_color}>0</div>
                                    }
                                    text={<div>Unable to Rate</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_brown}>1</div>
                                    }
                                    text={<div>Never</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>2</div>
                                    }
                                    text={<div>Rarely</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>3</div>
                                    }
                                    text={<div>Sometimes</div>}
                                />
                            </td>
                            <td>
                                {' '}
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>4</div>
                                    }
                                    text={<div>Most of the Time</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>5</div>
                                    }
                                    text={<div>Always</div>}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className={style.page_sub_title_center} style={{ textAlign: 'center' }}>
                    Average Score by Question
                </div>
                <MyResponsiveBar questionsData={props.metrics} />
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page8 = props => {
    return (
        <Page>
            <div className={style.page}>
                <table className={style.results_table_page_5}>{getStatTableBody(props)}</table>
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page9 = props => {
    return (
        <Page>
            <div className={style.page}>
                <div className={style.page_title_container}>
                    <div className={style.page_title}>{props.title}</div>
                    <div className={style.page_title_line} />
                </div>
                {/* <img src={icon2} className={style.top_right_icon}></img> */}
                <div className={style.content_text}>
                    Overall Composite Score:{' '}
                    <span style={{ fontWeight: 'bold' }}>{getCompositeScore(props.metrics)}</span>
                </div>
                <table className={style.ratings_scale_table}>
                    <tbody>
                        <tr>
                            <th colSpan={6}>Building Healing Systems Rating Scale</th>
                        </tr>
                        <tr>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_without_color}>0</div>
                                    }
                                    text={<div>Unable to Rate</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_brown}>1</div>
                                    }
                                    text={<div>Never</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>2</div>
                                    }
                                    text={<div>Rarely</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>3</div>
                                    }
                                    text={<div>Sometimes</div>}
                                />
                            </td>
                            <td>
                                {' '}
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>4</div>
                                    }
                                    text={<div>Most of the Time</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>5</div>
                                    }
                                    text={<div>Always</div>}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className={style.page_sub_title_center} style={{ textAlign: 'center' }}>
                    Average Score by Question
                </div>
                <MyResponsiveBar questionsData={props.metrics} />
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page10 = props => {
    return (
        <Page>
            <div className={style.page}>
                <table className={style.results_table_page_5}>{getStatTableBody(props)}</table>
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page11 = props => {
    return (
        <Page>
            <div className={style.page}>
                <div className={style.page_title_container}>
                    <div className={style.page_title}>{props.title}</div>
                    <div className={style.page_title_line} />
                </div>
                {/* <img src={icon2} className={style.top_right_icon}></img> */}
                <div className={style.content_text}>
                    Overall Composite Score:{' '}
                    <span style={{ fontWeight: 'bold' }}>{getCompositeScore(props.metrics)}</span>
                </div>
                <table className={style.ratings_scale_table}>
                    <tbody>
                        <tr>
                            <th colSpan={6}>Building Healing Systems Rating Scale</th>
                        </tr>
                        <tr>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_without_color}>0</div>
                                    }
                                    text={<div>Unable to Rate</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_brown}>1</div>
                                    }
                                    text={<div>Never</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>2</div>
                                    }
                                    text={<div>Rarely</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>3</div>
                                    }
                                    text={<div>Sometimes</div>}
                                />
                            </td>
                            <td>
                                {' '}
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>4</div>
                                    }
                                    text={<div>Most of the Time</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>5</div>
                                    }
                                    text={<div>Always</div>}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className={style.page_sub_title_center} style={{ textAlign: 'center' }}>
                    Average Score by Question
                </div>
                <MyResponsiveBar questionsData={props.metrics} />
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page12 = props => {
    return (
        <Page>
            <div className={style.page}>
                <table className={style.results_table_page_5}>{getStatTableBody(props)}</table>
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page13 = props => {
    return (
        <Page>
            <div className={style.page}>
                <div className={style.page_title_container}>
                    <div className={style.page_title}>{props.title}</div>
                    <div className={style.page_title_line} />
                </div>
                {/* <img src={icon2} className={style.top_right_icon}></img> */}
                <div className={style.content_text}>
                    Overall Composite Score:{' '}
                    <span style={{ fontWeight: 'bold' }}>{getCompositeScore(props.metrics)}</span>
                </div>
                <table className={style.ratings_scale_table}>
                    <tbody>
                        <tr>
                            <th colSpan={6}>Building Healing Systems Rating Scale</th>
                        </tr>
                        <tr>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_without_color}>0</div>
                                    }
                                    text={<div>Unable to Rate</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_brown}>1</div>
                                    }
                                    text={<div>Never</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>2</div>
                                    }
                                    text={<div>Rarely</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>3</div>
                                    }
                                    text={<div>Sometimes</div>}
                                />
                            </td>
                            <td>
                                {' '}
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>4</div>
                                    }
                                    text={<div>Most of the Time</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>5</div>
                                    }
                                    text={<div>Always</div>}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className={style.page_sub_title_center} style={{ textAlign: 'center' }}>
                    Average Score by Question
                </div>
                <MyResponsiveBar questionsData={props.metrics} />
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page14 = props => {
    return (
        <Page>
            <div className={style.page}>
                <table className={style.results_table_page_5}>{getStatTableBody(props)}</table>
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page15 = props => {
    return (
        <Page>
            <div className={style.page}>
                <div className={style.page_title_container}>
                    <div className={style.page_title}>{props.title}</div>
                    <div className={style.page_title_line} />
                </div>
                {/* <img src={icon2} className={style.top_right_icon}></img> */}
                <div className={style.content_text}>
                    Overall Composite Score:{' '}
                    <span style={{ fontWeight: 'bold' }}>{getCompositeScore(props.metrics)}</span>
                </div>
                <table className={style.ratings_scale_table}>
                    <tbody>
                        <tr>
                            <th colSpan={6}>Building Healing Systems Rating Scale</th>
                        </tr>
                        <tr>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_without_color}>0</div>
                                    }
                                    text={<div>Unable to Rate</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_brown}>1</div>
                                    }
                                    text={<div>Never</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>2</div>
                                    }
                                    text={<div>Rarely</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>3</div>
                                    }
                                    text={<div>Sometimes</div>}
                                />
                            </td>
                            <td>
                                {' '}
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>4</div>
                                    }
                                    text={<div>Most of the Time</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>5</div>
                                    }
                                    text={<div>Always</div>}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className={style.page_sub_title_center} style={{ textAlign: 'center' }}>
                    Average Score by Question
                </div>
                <MyResponsiveBar questionsData={props.metrics} />
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page16 = props => {
    return (
        <Page>
            <div className={style.page}>
                <table className={style.results_table_page_5}>{getStatTableBody(props)}</table>
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page17 = props => {
    return (
        <Page>
            <div className={style.page}>
                <div className={style.page_title_container}>
                    <div className={style.page_title}>{props.title}</div>
                    <div className={style.page_title_line} />
                </div>
                {/* <img src={icon2} className={style.top_right_icon}></img> */}
                <div className={style.content_text}>
                    Overall Composite Score:{' '}
                    <span style={{ fontWeight: 'bold' }}>{getCompositeScore(props.metrics)}</span>
                </div>
                <table className={style.ratings_scale_table}>
                    <tbody>
                        <tr>
                            <th colSpan={6}>Building Healing Systems Rating Scale</th>
                        </tr>
                        <tr>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_without_color}>0</div>
                                    }
                                    text={<div>Unable to Rate</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_brown}>1</div>
                                    }
                                    text={<div>Never</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>2</div>
                                    }
                                    text={<div>Rarely</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>3</div>
                                    }
                                    text={<div>Sometimes</div>}
                                />
                            </td>
                            <td>
                                {' '}
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>4</div>
                                    }
                                    text={<div>Most of the Time</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>5</div>
                                    }
                                    text={<div>Always</div>}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className={style.page_sub_title_center} style={{ textAlign: 'center' }}>
                    Average Score by Question
                </div>
                <MyResponsiveBar questionsData={props.metrics} />
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page18 = props => {
    return (
        <Page>
            <div className={style.page}>
                <table className={style.results_table_page_5}>{getStatTableBody(props)}</table>
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page19 = props => {
    return (
        <Page>
            <div className={style.page}>
                <div className={style.page_title_container}>
                    <div className={style.page_title}>{props.title}</div>
                    <div className={style.page_title_line} />
                </div>
                {/* <img src={icon2} className={style.top_right_icon}></img> */}
                <div className={style.content_text}>
                    Overall Composite Score:{' '}
                    <span style={{ fontWeight: 'bold' }}>{getCompositeScore(props.metrics)}</span>
                </div>
                <table className={style.ratings_scale_table}>
                    <tbody>
                        <tr>
                            <th colSpan={6}>Building Healing Systems Rating Scale</th>
                        </tr>
                        <tr>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_without_color}>0</div>
                                    }
                                    text={<div>Unable to Rate</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_brown}>1</div>
                                    }
                                    text={<div>Never</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>2</div>
                                    }
                                    text={<div>Rarely</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_yellow}>3</div>
                                    }
                                    text={<div>Sometimes</div>}
                                />
                            </td>
                            <td>
                                {' '}
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>4</div>
                                    }
                                    text={<div>Most of the Time</div>}
                                />
                            </td>
                            <td>
                                <BoldTextAndNormalText
                                    boldText={
                                        <div className={style.ratings_scale_grid_bold_number_with_color_green}>5</div>
                                    }
                                    text={<div>Always</div>}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className={style.page_sub_title_center} style={{ textAlign: 'center' }}>
                    Average Score by Question
                </div>
                <MyResponsiveBar questionsData={props.metrics} />
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page20 = props => {
    return (
        <Page>
            <div className={style.page}>
                <table className={style.results_table_page_19}>{getStatTableBody(props)}</table>
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page21 = props => {
    return (
        <Page>
            <div className={style.page}>
                <div className={style.page_title_container}>
                    <div className={style.page_title} style={{ fontSize: '2.1em' }}>
                        {props.title}
                    </div>
                    <div className={style.page_title_line} />
                </div>
                <div className={style.content_text}>
                    Thank you for completing the Building Healing Systems Trauma-Informed Organizational Assessment. We
                    encourage you to meet with your team, review your scores on each domain provided in this report, and
                    engage in a continuous quality improvement process to increase trauma-informed practices at your
                    organization.
                </div>
                <div className={style.content_text}>
                    Additional resources to support continuous quality improvement are available in the Resource Center
                    to develop action steps and guide improvement. Also, The National Child Traumatic Stress Network
                    (NCTSN) has resources available here:{' '}
                    <span style={{ color: '#047a5d', fontWeight: 'bold' }}>
                        NCTSN Trauma-Informed Organizational Assessment (TIOA) Course | The National Child Traumatic
                        Stress Network.
                    </span>{' '}
                    Consider using these materials and this Strategic Planning Guide to create a plan for improving your
                    organization{"'"}s practices in one or more areas.
                </div>
                <div className={style.content_text}>
                    Building healing systems is an ongoing process that requires a commitment to growth and change. The
                    focus of your efforts will look different given the {'"'}season{'"'} (e.g., wants, needs, capacity,
                    resources, barriers, etc.).
                </div>
                <div className={style.content_text} style={{ fontWeight: 'bold' }}>
                    To determine next steps, after reviewing your assessment results, list the domain(s) on which you
                    scored in the Seeding or Budding phase and evaluate your system{"'"}s capacity and resources to
                    implement change in each domain.
                </div>
                <table className={style.results_table} style={{ marginTop: '30px' }}>
                    <tbody>
                        <tr>
                            <th>Domain</th>
                            <th>
                                Need for change{' '}
                                <div>
                                    <br />
                                    (1-10)
                                </div>
                                <br />
                                1=no need<div>10=great need</div>
                            </th>
                            <th>
                                Desire for change{' '}
                                <div>
                                    <br />
                                    (1-10)
                                </div>
                                <br />
                                1=no desire<div>10=great desire</div>
                            </th>
                            <th>
                                Resources to achieve change{' '}
                                <div>
                                    <br />
                                    (1-10)
                                </div>
                                <br />
                                1=no resources<div>10=many resources</div>
                            </th>
                            <th>
                                Barriers to change{' '}
                                <div>
                                    <br />
                                    (1-10)
                                </div>
                                <br />
                                1=no barriers<div>10=many barriers</div>
                            </th>
                        </tr>
                        <tr>
                            <td>1.</td>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td>2.</td>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td>3.</td>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                    </tbody>
                </table>
                <br />
                <div
                    className={style.content_text}
                    style={{ fontWeight: 'bold', marginTop: '20px', textIndent: '25px' }}
                >
                    Select one domain from the list above that your team wants to improve.
                </div>
                <div className={style.content_text} style={{ fontWeight: 'bold', textIndent: '25px' }}>
                    DOMAIN:
                    <span style={{ fontFamily: 'DM Serif ' }}>
                        ______________________________________________________
                    </span>
                </div>
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page22 = props => {
    return (
        <Page>
            <div className={style.page}>
                <div className={style.page_title_container}>
                    <div className={style.page_title}>{props.title}</div>
                    <div className={style.page_title_line} />
                </div>
                <div className={style.content_text}>
                    <span style={{ fontWeight: 'bold' }}> Please state a specific goal within this domain. </span>
                    {props.paragraph1}
                </div>
                <div className={style.content_text} style={{ fontWeight: 'bold' }}>
                    GOAL:
                    <div style={{ textIndent: '8px', marginTop: '12px' }}>
                        <span style={{ fontFamily: 'DM Serif ' }}>
                            _______________________________________________________________________________________________
                        </span>
                    </div>
                    <br />
                    <div style={{ textIndent: '8px' }}>
                        <span style={{ fontFamily: 'DM Serif ' }}>
                            _______________________________________________________________________________________________
                        </span>
                    </div>
                </div>
                <div className={style.content_text} style={{ marginTop: '25px' }}>
                    <span style={{ fontWeight: 'bold' }}>
                        How will you know if you{"'"}ve achieved success within this goal?
                    </span>{' '}
                    {props.paragraph2}
                </div>
                <div className={style.content_text} style={{ fontWeight: 'bold', marginTop: '25px' }}>
                    INDICATOR OF SUCCESS:
                    <div style={{ textIndent: '8px', marginTop: '12px' }}>
                        <span style={{ fontFamily: 'DM Serif ' }}>
                            _______________________________________________________________________________________________
                        </span>
                    </div>
                    <br />
                    <div style={{ textIndent: '8px' }}>
                        <span style={{ fontFamily: 'DM Serif ' }}>
                            _______________________________________________________________________________________________
                        </span>
                    </div>
                </div>
                <div className={style.content_text} style={{ marginTop: '25px' }}>
                    <span style={{ fontWeight: 'bold' }}>QUESTIONS TO CONSIDER:</span>
                    <ol style={{ marginTop: '20px' }}>
                        <li>{props.Q1}</li>
                        <ol>
                            <li>{props.Q1_1}</li>
                            <li>{props.Q1_2}</li>
                            <li>{props.Q1_3}</li>
                        </ol>
                        <li>{props.Q2}</li>
                        <ol>
                            <li>{props.Q2_1}</li>
                            <li>{props.Q2_2}</li>
                            <li>{props.Q2_3}</li>
                        </ol>
                        <li>{props.Q3}</li>
                    </ol>
                </div>
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page23 = props => {
    return (
        <Page>
            <div className={style.page}>
                <div className={style.content_text}>
                    <span style={{ fontWeight: 'bold' }}>
                        IMPORTANT: You will need to run separate reports to view your data disaggregated, or broken
                        down, by demographic groups (e.g., Direct Care staff, Administrators, BIPOC, LGBTQIA+, etc.).
                        This may reveal differences in responses based on relevant groups that may influence your goals
                        and action steps.
                    </span>{' '}
                    For instance, let{"'"}s imagine your organization{"'"}s average rating for item 10 on the Screening
                    domain was <span style={{ color: '#f9a62b', fontWeight: 'bold' }}>3.8 (Budding)</span>, but, when
                    disaggregated by Direct Care staff{' '}
                    <span style={{ color: '#a3692e', fontWeight: 'bold' }}>(Mean = 1.9; Seeding)</span> and
                    Administrators <span style={{ color: '#047a5d', fontWeight: 'bold' }}>(Mean = 4, Blooming)</span>{' '}
                    you identified a discrepancy. Direct Care staff, who perform the screenings, are rating this item
                    lower than Administrators. If you believe that, for item 10, Direct Care staff responses may be of
                    most interest to you, for instance, because their responses may be more valid since they are
                    providing the screening services, you may consider this as a{' '}
                    <span style={{ color: '#a3692e', fontWeight: 'bold' }}>Seeding</span> area for growth and thus
                    decide to prioritize this domain for quality improvement. There may also be cases where you want to
                    disaggregate data to look at differences in reports related to cultural identity. For example, item
                    12 in the Addressing the Intersections of Culture, Race, and Trauma domain asks about coverage of
                    the historical relationship between communities that have been historically marginalized (e.g.,
                    communities of color, LGBTQIA+ community) and health care systems, including harm caused, in
                    professional development activities. For this question, you may be particularly interested in how
                    your BIPOC or LGBTQIA+ staff responded to this question and disaggregate by those groups.{' '}
                    <span style={{ fontWeight: 'bold' }}>
                        A broad range may be a hint that there is disagreement from respondents.
                    </span>
                </div>
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page24 = props => {
    return (
        <Page>
            <div className={style.page}>
                <div className={style.page_title_container}>
                    <div className={style.page_title}>{props.title}</div>
                    <div className={style.page_title_line} />
                </div>
                <div className={style.content_text}>
                    <span style={{ fontWeight: 'bold' }}>Who will be involved?</span> List the individuals who will help
                    move this goal forward and their role. Consider multidisciplinary stakeholders from the district,
                    school, community, and family levels.
                </div>
                <table className={style.results_table} style={{ marginTop: '5px' }}>
                    <tbody>
                        <tr>
                            <th>#</th>
                            <th>Individual</th>
                            <th>Role</th>
                        </tr>
                        <tr>
                            <td>1.</td>
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td>2.</td>
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td>3.</td>
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td>4.</td>
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td>5.</td>
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td>6.</td>
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td>7.</td>
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td>8.</td>
                            <td />
                            <td />
                        </tr>
                    </tbody>
                </table>
                <div className={style.content_text} style={{ marginTop: '15px' }}>
                    Identify 3-5 action steps that can be taken toward achieving this goal.
                </div>
                <div className={style.content_text} style={{ paddingTop: '4px', paddingBottom: '4px' }}>
                    <div style={{ textIndent: '8px' }}>
                        <span style={{ fontWeight: 'bold' }}>1.</span>
                        <div>
                            <span style={{ fontFamily: 'DM Serif ' }}>
                                _______________________________________________________________________________________________
                            </span>
                        </div>
                        <div>
                            <span style={{ fontFamily: 'DM Serif ' }}>
                                _______________________________________________________________________________________________
                            </span>
                        </div>
                    </div>
                </div>
                <div className={style.content_text} style={{ paddingTop: '4px', paddingBottom: '4px' }}>
                    <div style={{ textIndent: '8px' }}>
                        <span style={{ fontWeight: 'bold' }}>2.</span>
                        <div>
                            <span style={{ fontFamily: 'DM Serif ' }}>
                                _______________________________________________________________________________________________
                            </span>
                        </div>
                        <div>
                            <span style={{ fontFamily: 'DM Serif ' }}>
                                _______________________________________________________________________________________________
                            </span>
                        </div>
                    </div>
                </div>
                <div className={style.content_text} style={{ paddingTop: '4px', paddingBottom: '4px' }}>
                    <div style={{ textIndent: '8px' }}>
                        <span style={{ fontWeight: 'bold' }}>3.</span>
                        <div>
                            <span style={{ fontFamily: 'DM Serif ' }}>
                                _______________________________________________________________________________________________
                            </span>
                        </div>
                        <div>
                            <span style={{ fontFamily: 'DM Serif ' }}>
                                _______________________________________________________________________________________________
                            </span>
                        </div>
                    </div>
                </div>
                <div className={style.content_text} style={{ paddingTop: '4px', paddingBottom: '4px' }}>
                    <div style={{ textIndent: '8px' }}>
                        <span style={{ fontWeight: 'bold' }}>4.</span>
                        <div>
                            <span style={{ fontFamily: 'DM Serif ' }}>
                                _______________________________________________________________________________________________
                            </span>
                        </div>
                        <div>
                            <span style={{ fontFamily: 'DM Serif ' }}>
                                _______________________________________________________________________________________________
                            </span>
                        </div>
                    </div>
                </div>
                <div className={style.content_text} style={{ paddingTop: '4px', paddingBottom: '4px' }}>
                    <div style={{ textIndent: '8px' }}>
                        <span style={{ fontWeight: 'bold' }}>5.</span>
                        <div>
                            <span style={{ fontFamily: 'DM Serif ' }}>
                                _______________________________________________________________________________________________
                            </span>
                        </div>
                        <div>
                            <span style={{ fontFamily: 'DM Serif ' }}>
                                _______________________________________________________________________________________________
                            </span>
                        </div>
                    </div>
                </div>
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

const Page25 = props => {
    return (
        <Page>
            <div className={style.page}>
                <div className={style.page_sub_title_left} style={{ paddingBottom: '12px' }}>
                    Timeline for improvement
                </div>
                <div className={style.content_text} style={{ paddingBottom: '35px', textIndent: '8px' }}>
                    Short term: 0-6 months
                </div>
                <table className={style.empty_table} style={{ marginTop: '10px' }}>
                    <tbody>
                        <tr>
                            <th>Action Step</th>
                            <th>Individual(s) Response</th>
                            <th>Data to Evaluate Change</th>
                            <th>Date</th>
                        </tr>
                        <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                    </tbody>
                </table>
                <div className={style.page_sub_title_left} style={{ paddingBottom: '12px' }}>
                    Timeline for improvement
                </div>
                <div className={style.content_text} style={{ paddingBottom: '35px', textIndent: '8px' }}>
                    Long term: 7-24 months
                </div>
                <table className={style.empty_table} style={{ marginTop: '10px' }}>
                    <tbody>
                        <tr>
                            <th>Action Step</th>
                            <th>Individual(s) Response</th>
                            <th>Data to Evaluate Change</th>
                            <th>Date</th>
                        </tr>
                        <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                        <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                    </tbody>
                </table>
                <Footer pageNumber={props.pageNumber} />
            </div>
        </Page>
    );
};

Report.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Report.defaultProps = {};

export default Report;
