import React from 'react';
import { NavLink } from 'cccisd-react-router';
import NavBar from './NavBar';
import logo from './logo_cropped.png';
import style from './style.css';

const Fortress = window.cccisd.fortress;
const Env = window.cccisd.env;

export const ExternalLink = ({ to, children, className }) => {
    return (
        <li className={className}>
            <a href={to}>{children}</a>
        </li>
    );
};

const Header = () => {
    const pathArray = window.parent.location.pathname.split('/').filter(Boolean);
    const endpoint = pathArray.length > 0 ? pathArray[pathArray.length - 1] : '';
    const { wpUrl } = Env;

    const getUserNav = () => {
        const userType = Fortress.user.acting.data_type; // guest, uberadmin, instructor
        if (userType === 'instructor') {
            return (
                <>
                    <li>
                        <NavLink to="/mywellbeing" className={endpoint === 'mywellbeing' ? style.active : ''}>
                            My Well-being
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/resourcecenter" className={endpoint === 'resourcecenter' ? style.active : ''}>
                            Resources
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to="/rolePicker" className={endpoint === 'rolePicker' ? style.active : ''}>
                            My Account
                        </NavLink>
                    </li>
                </>
            );
        }
        if (userType === 'orgAdmin') {
            return (
                <>
                    <li>
                        <NavLink to="/overview" className={endpoint === 'overview' ? style.active : ''}>
                            Overview
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/orgAssessment" className={endpoint === 'orgAssessment' ? style.active : ''}>
                            Organization Assessment
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/resourcecenter" className={endpoint === 'resourcecenter' ? style.active : ''}>
                            Resources
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/manage" className={endpoint === 'manage' ? style.active : ''}>
                            Manage
                        </NavLink>
                    </li>
                </>
            );
        }
        if (userType === 'orgTeamMember') {
            return (
                <>
                    <li>
                        <NavLink to="/overview" className={endpoint === 'overview' ? style.active : ''}>
                            Overview
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/orgAssessment" className={endpoint === 'orgAssessment' ? style.active : ''}>
                            Organization Assessment
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/resourcecenter" className={endpoint === 'resourcecenter' ? style.active : ''}>
                            Resources
                        </NavLink>
                    </li>
                </>
            );
        }
        if (userType === 'uberadmin') {
            return (
                <>
                    <li>
                        <NavLink to="/data" className={endpoint === 'data' ? style.active : ''}>
                            Data
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/resourcecenter" className={endpoint === 'resourcecenter' ? style.active : ''}>
                            Resources
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/resources" className={endpoint === 'resources' ? style.active : ''}>
                            Resource Admin
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/appdefs/logs"
                            className={['logs', 'appdefs'].includes(endpoint) ? style.active : ''}
                        >
                            System Logs
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/siteContent" className={endpoint === 'siteContent' ? style.active : ''}>
                            Edit Site Content
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/manage" className={endpoint === 'manage' ? style.active : ''}>
                            Manage
                        </NavLink>
                    </li>
                </>
            );
        }
    };

    return (
        <>
            <NavBar
                className={style.navbar}
                logo={<img src={logo} className={style.headerLogo} alt="ACES" />}
                logoLinkTo={wpUrl}
            >
                {/* <ExternalLink to={wpUrl} className={[''].includes(endpoint) ? style.active : ''}>
                    Home
                </ExternalLink>
                <ExternalLink to={wpUrl + '/about'} className={['about'].includes(endpoint) ? style.active : ''}>
                    About
                </ExternalLink> */}
                {getUserNav()}
            </NavBar>
        </>
    );
};

export default Header;
