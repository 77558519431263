import { handleActions, createAction } from 'redux-actions';
import { client } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';
import { parseOrgReportData, getOrgReportPageStructure } from '../selectors/orgReport.js';
import { mapFilterValuesToSurveyValues } from './helpers.js';
import _pick from 'lodash/pick';

import orgReportQuery from './orgReport.graphql';
import samhsaCompletion from './samhsaCompletion.graphql';

const Boilerplate = window.cccisd.boilerplate;

// Initial state
export const initialState = {
    orgReportDataLoaded: false,
    individualReportDataLoaded: false,
    reportDeploymentListLoaded: false,
    orgReportPageStructure: [],
    samhsaComplete: false,
    mywellbeingComplete: false,
};

// Actions
const SET_OVERVIEW_FIELDS = 'app/providerwellbeing/SET_OVERVIEW_FIELDS';
const SET_ORG_REPORT_FIELDS = 'app/providerwellbeing/SET_ORG_REPORT_FIELDS';
const SET_ORG_REPORT_DATA = 'app/providerwellbeing/SET_ORG_REPORT_DATA';
const SET_INDIVIDUAL_REPORT_DATA = 'app/providerwellbeing/SET_INDIVIDUAL_REPORT_DATA';
const SET_REPORT_DEPLOYMENT_LIST = 'app/providerwellbeing/SET_REPORT_DEPLOYMENT_LIST';
const SET_REPORT_DEPLOYMENT_LIST_LOADED = 'app/providerwellbeing/SET_REPORT_DEPLOYMENT_LIST_LOADED';
const SET_ORG_REPORT_DATA_LOADED = 'app/providerwellbeing/SET_ORG_REPORT_DATA_LOADED';
const SET_INDIVIDUAL_REPORT_DATA_LOADED = 'app/providerwellbeing/SET_INDIVIDUAL_REPORT_DATA_LOADED';
const SET_USER_GROUPS = 'app/providerwellbeing/SET_USER_GROUPS';
const SET_ORG_REPORT_PAGE_STRUCTURE = 'app/providerwellbeing/SET_ORG_REPORT_PAGE_STRUCTURE';
const SET_SAMHSA_COMPLETE = 'app/providerwellbeing/SET_SAMHSA_COMPLETE';
const SET_MYWELLBEING_COMPLETE = 'app/providerwellbeing/SET_MYWELLBEING_COMPLETE';

// Action Creators
export const setOverviewFields = createAction(SET_OVERVIEW_FIELDS);
export const setOrgReportFields = createAction(SET_ORG_REPORT_FIELDS);
export const setOrgReportData = createAction(SET_ORG_REPORT_DATA);
export const setIndividualReportData = createAction(SET_INDIVIDUAL_REPORT_DATA);
export const setOrgReportDataLoaded = createAction(SET_ORG_REPORT_DATA_LOADED);
export const setIndividualReportDataLoaded = createAction(SET_INDIVIDUAL_REPORT_DATA_LOADED);
export const setUserGroups = createAction(SET_USER_GROUPS);
export const setReportDeploymentList = createAction(SET_REPORT_DEPLOYMENT_LIST);
export const setReportDeploymentListLoaded = createAction(SET_REPORT_DEPLOYMENT_LIST_LOADED);
export const setOrgReportPageStructure = createAction(SET_ORG_REPORT_PAGE_STRUCTURE);
export const setSamhsaComplete = createAction(SET_SAMHSA_COMPLETE);
export const setMywellbeingComplete = createAction(SET_MYWELLBEING_COMPLETE);

export const updateParams = (params, key) => async () => {
    const response = await axios.post(Boilerplate.route('providerwellbeing.set.data'), {
        data: JSON.stringify(params),
        data_key: key,
    });
    if (response.data.success) {
        notification({
            message: `Site fields have been updated.`,
            type: 'success',
            duration: 8000,
        });
    }
};

// fields: JSON object, contains the default text to be used.
// key: string, 'overview'
export const getSavedParams = (fields, key) => {
    return async dispatch => {
        const response = await axios.get(Boilerplate.route('providerwellbeing.get.data'), {
            data_key: key,
        });
        let params = {};
        if (fields) {
            Object.keys(fields.messages).forEach(handle => {
                params[handle] = fields.messages[handle].message;
            });
            if (response.data.data[key]) {
                params = {
                    ...params,
                    ...JSON.parse(response.data.data[key].data),
                };
            }
        }

        if (key === 'overview') {
            await dispatch(setOverviewFields(params));
        }
        if (key === 'orgReport') {
            await dispatch(setOrgReportFields(params));
        }
    };
};

export const getUserGroups = () => {
    return async (dispatch, getState) => {
        let response = await axios.get(Boilerplate.route('all.pawn.roles'));

        const userGroupData = response.data.roles.map(role => {
            return _pick(role, ['id', 'random_hash', 'data_type', 'group']);
        });
        await dispatch(setUserGroups(userGroupData));
    };
};

export const getOrgReportData = filters => {
    return async (dispatch, getState) => {
        // console.log('filters to query', filters);
        const {
            genderFilters,
            sexualOrientationFilters,
            roleFilters,
        } = mapFilterValuesToSurveyValues(filters);

        dispatch(setOrgReportDataLoaded(false));

        const response = await client.query({
            query: orgReportQuery,
            variables: {
                deploymentId: filters.assessment,
                age: filters.age.length > 0 ? filters.age.map(a => a.value) : [],
                sexualOrientation: sexualOrientationFilters,
                gender: genderFilters,
                role: roleFilters,
            },
            fetchPolicy: 'network-only',
            // cccisdReadReplica: true,
        });
        // console.log(response.data);
        const orgReportData = parseOrgReportData(response.data, filters);
        dispatch(setOrgReportData(orgReportData));
        dispatch(setOrgReportPageStructure(getOrgReportPageStructure(orgReportData)));
        dispatch(setOrgReportDataLoaded(true));
    };
};

export const getIndividualReportData = deploymentId => {
    return async (dispatch, getState) => {
        dispatch(setIndividualReportDataLoaded(false));

        const response = await client.query({
            query: orgReportQuery,
            variables: {
                deploymentId,
                age: [],
                sexualOrientation: [],
                gender: [],
                role: [],
            },
            fetchPolicy: 'network-only',
            // cccisdReadReplica: true,
        });
        // console.log(response.data);
        const individualReportData = parseOrgReportData(response.data, {
            deploymentId,
            age: [],
            ethnicity: [],
            gender: [],
            race: [],
            role: [],
            sexualOrientation: [],
        });
        // console.log('ind report data output', individualReportData);
        dispatch(setIndividualReportData(individualReportData));
        dispatch(setIndividualReportDataLoaded(true));
    };
};

export const getSamhsaCompletion = () => {
    return async (dispatch, getState) => {
        const pawnIds = getState().app.providerwellbeing.userGroups.map(u => u.id);

        const response = await client.query({
            query: samhsaCompletion,
            variables: {
                pawnIds,
            },
            fetchPolicy: 'network-only',
        });
        const roleList = response.data.roles.anyRoleList;
        const hasCompletedSamhsaSurvey = roleList.some(
            u => u.assignmentProgress.status === 'Complete'
        );
        dispatch(setSamhsaComplete(hasCompletedSamhsaSurvey));
    };
};

// Reducer
export default handleActions(
    {
        [SET_OVERVIEW_FIELDS]: (state, action) => ({
            ...state,
            overviewFields: action.payload,
        }),
        [SET_ORG_REPORT_FIELDS]: (state, action) => ({
            ...state,
            orgReportFields: action.payload,
        }),
        [SET_USER_GROUPS]: (state, action) => ({
            ...state,
            userGroups: action.payload,
        }),
        [SET_ORG_REPORT_DATA]: (state, action) => ({
            ...state,
            orgReportData: action.payload,
        }),
        [SET_ORG_REPORT_DATA_LOADED]: (state, action) => ({
            ...state,
            orgReportDataLoaded: action.payload,
        }),
        [SET_INDIVIDUAL_REPORT_DATA]: (state, action) => ({
            ...state,
            individualReportData: action.payload,
        }),
        [SET_INDIVIDUAL_REPORT_DATA_LOADED]: (state, action) => ({
            ...state,
            individualReportDataLoaded: action.payload,
        }),
        [SET_REPORT_DEPLOYMENT_LIST]: (state, action) => ({
            ...state,
            reportDeploymentList: action.payload.map(d => {
                return { id: d.deploymentId, handle: '', label: d.label, value: d.deploymentId };
            }),
        }),
        [SET_REPORT_DEPLOYMENT_LIST_LOADED]: (state, action) => ({
            ...state,
            reportDeploymentListLoaded: action.payload,
        }),
        [SET_ORG_REPORT_PAGE_STRUCTURE]: (state, action) => {
            return {
                ...state,
                orgReportPageStructure: action.payload,
            };
        },
        [SET_SAMHSA_COMPLETE]: (state, action) => {
            return {
                ...state,
                samhsaComplete: action.payload,
            };
        },
        [SET_MYWELLBEING_COMPLETE]: (state, action) => {
            return {
                ...state,
                mywellbeingComplete: action.payload,
            };
        },
    },
    initialState
);
