import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import IconOptional from 'cccisd-icons/circle';
import IconRequired from 'cccisd-icons/circle2';
import IconLock from 'cccisd-icons/svg/lock4';
import IconPlay from 'cccisd-icons/svg/play4';
import IconReplay from 'cccisd-icons/svg/spinner11';
import { Html } from 'cccisd-wysiwyg';
import { Player } from 'cccisd-laravel-flow';
import Modal from 'cccisd-modal';
import style from '../style.css';

const translate = {
    en: {
        lessonText: 'LESSON',
        play: 'Play',
        quickReview: '',
        replay: 'Replay',
        resume: 'Resume',
    },
    es: {
        lessonText: 'LECCIÓN',
        play: 'Empezar',
        quickReview: '',
        replay: 'Repetirse',
        resume: 'Reanudar',
    },
};

const LessonCard = ({
    item,
    language,
    lessonNum,
    lessons = [],
    highlighting,
    getHighlightColor,
}) => {
    language = Object.keys(translate).includes(language) ? language : 'en';
    const action = getAction(item);
    const hasNextLesson = lessons.length >= lessonNum + 2;
    const lessonAreaRef = useRef();
    const [lessonAreaHeight, setLessonAreaHeight] = useState(109);

    useEffect(() => {
        if (lessonAreaRef.current && lessonAreaRef.current.offsetHeight !== lessonAreaHeight) {
            setLessonAreaHeight(lessonAreaRef.current.offsetHeight);
        }

        function handleResize() {
            if (lessonAreaRef.current && lessonAreaRef.current.offsetHeight !== lessonAreaHeight) {
                setLessonAreaHeight(lessonAreaRef.current.offsetHeight);
            }
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    function getAction(lesson) {
        switch (true) {
            case lesson.isCompleted:
                return 'replay';
            case lesson.isResume:
                return 'resume';
            default:
                return 'play';
        }
    }

    function renderActionButton() {
        let playIcon = item.isAllowed ? <IconPlay /> : <IconLock />;
        const buttonData = {
            play: { className: 'btn-primary', icon: playIcon },
            resume: { className: 'btn-primary', icon: null },
            replay: { className: style.grayButton, icon: <IconReplay /> },
        };
        const buttonText = translate[language][action];
        const buttonInfo = buttonData[action];

        return (
            <button
                type="button"
                className={classnames(
                    'btn btn-block',
                    buttonInfo.className,
                    !item.isAllowed && 'disabled'
                )}
                onClick={item.setCurrent}
            >
                {buttonInfo.icon && (
                    <span className={style.actionIcon}>{buttonInfo.icon}&nbsp;</span>
                )}
                {buttonText}
            </button>
        );
    }

    function renderQuickReview() {
        const { isCompleted } = item;

        const printableModal = (
            <Modal
                trigger={
                    <button type="button" className="btn btn-default">
                        View PDF
                    </button>
                }
                size="large"
                title={item.title}
            >
                <Player {...item} isPrintMode />
            </Modal>
        );

        return (
            <div className={style.quickReviewWrapper}>
                <div>
                    <p className={style.quickReviewTitle}>{translate[language].quickReview}</p>
                    <Html content={item.quickReview} />
                </div>
                {isCompleted && (
                    <div className={style.quickReviewPrintButton}>{printableModal}</div>
                )}
            </div>
        );
    }

    function renderLessonMain() {
        let highlight;
        switch (highlighting) {
            case 'required':
                highlight = item.isRequired && (
                    <span style={{ color: getHighlightColor() }} className={style.highlightIcon}>
                        <IconRequired />
                        &nbsp;&nbsp;
                    </span>
                );
                break;
            case 'optional':
                highlight = !item.isRequired && (
                    <span style={{ color: getHighlightColor() }} className={style.highlightIcon}>
                        <IconOptional />
                        &nbsp;&nbsp;
                    </span>
                );
                break;
            default:
            // leave undefined
        }

        return (
            <div className={style.lessonCard} ref={lessonAreaRef}>
                <div className={style.lessonInnerWrapper}>
                    <p className={style.lessonText}>
                        {highlight}
                        {translate[language].lessonText} {lessonNum + 1}
                    </p>
                    <p>
                        {typeof item.title === 'string' ? (
                            <Html content={item.title} />
                        ) : (
                            <Html content={item.title.content} />
                        )}
                    </p>
                    {renderActionButton()}
                </div>
                <div
                    className={classnames(
                        style.quickReviewDivider,
                        !!item.quickReview && style.quickReviewDividerColor
                    )}
                />
                {renderQuickReview()}
            </div>
        );
    }

    function renderLessonStatus() {
        return (
            <>
                <div className={classnames(style.lessonStatusOuter, style[`lesson${action}`])}>
                    {action === 'resume' && (
                        <div
                            className={classnames(style.lessonplay, style.lessonStatusOuterPartial)}
                        />
                    )}
                    <div className={style.lessonStatusInner}>{lessonNum + 1}</div>
                </div>
                {hasNextLesson && (
                    <div
                        className={classnames(style.lessonStatusBeam, style[`lesson${action}`])}
                        style={{ height: `${lessonAreaHeight}px` }}
                    />
                )}
            </>
        );
    }

    return (
        <div>
            {renderLessonStatus()}
            {renderLessonMain()}
        </div>
    );
};

export default LessonCard;
