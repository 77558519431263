import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'cccisd-axios';
import { Link } from 'cccisd-react-router';

import IconUser from 'cccisd-icons/user3';
import IconArrowLeft from 'cccisd-icons/arrow-left6';
import IconCheckmark from 'cccisd-icons/checkmark';

function NavBarLink({ to, children }) {
    return (
        <li>
            <Link to={to}>{children}</Link>
        </li>
    );
}

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

NavBar.propTypes = {
    className: PropTypes.string,
    logo: PropTypes.node,
    children: PropTypes.node,
    noLogoLink: PropTypes.bool,
    showMenu: PropTypes.bool,
    allowSwitchRoles: PropTypes.bool,
    hideSeparateRoleSwitcher: PropTypes.bool,
    showTheseProfileDataFields: PropTypes.object, // array of data field handles to include in profile update
    renderInModal: PropTypes.bool,
    logoLinkTo: PropTypes.string,
    showAlerts: PropTypes.bool,
    alertLimit: PropTypes.number,
};

export default function NavBar({
    className = '',
    logo = 'Logo',
    noLogoLink = false,
    showMenu = true,
    allowSwitchRoles = true,
    renderInModal = false,
    showAlerts = true,
    alertLimit,
    ...props
}) {
    // eslint-disable-next-line no-unused-vars
    const [isLoading, setIsLoading] = useState(false);

    const returnToUser = async e => {
        e && e.preventDefault();

        setIsLoading(true);
        await axios.get(Boilerplate.route('api.nexus.user.logoutAs'));
        window.location.href = Boilerplate.url(Fortress.settings.config.after_login_url);
    };

    const changeRole = async (pawnId, e) => {
        e && e.preventDefault();

        if (pawnId === Fortress.user.pawnId) {
            return;
        }

        setIsLoading(true);
        const response = await axios.post(Boilerplate.route('api.nexus.user.changeTo'), {
            pawn: pawnId,
        });
        if (response.status !== 200 || !response.data.data) {
            return;
        }
        let redirectUrl = Boilerplate.url(Fortress.settings.config.after_login_url);
        if (response.data.data.hasIncompleteSiteRegistration) {
            redirectUrl = Boilerplate.url('/account/registrationSurvey');
        }
        window.location.href = redirectUrl;
    };

    const renderLogo = () => {
        if (noLogoLink) {
            return (
                <div className="navbar-brand" style={{ padding: '0 10px' }}>
                    {logo}
                </div>
            );
        }
        if (props.logoLinkTo) {
            return (
                <a className="navbar-brand" style={{ padding: '0 10px' }} href={props.logoLinkTo}>
                    {logo}
                </a>
            );
        }
        return (
            <Link className="navbar-brand" style={{ padding: '0 10px' }} to="/">
                {logo}
            </Link>
        );
    };

    const menuItems = [];
    let returnToUserLink = null;

    if (Fortress.auth()) {
        let switchRoles = Fortress.user.pawns.filter(
            r => !['Guest', 'Respondent'].includes(r.name)
        );
        if (Fortress.user.loggedInAsAnotherUser) {
            returnToUserLink = (
                <li key="returnToUserLink">
                    <a href="#" onClick={returnToUser}>
                        <IconArrowLeft spaceRight /> Return to &quot;
                        {Fortress.user.actualUsername}&quot; user
                    </a>
                </li>
            );
        }

        if (Fortress.hasAccess('uber.admin')) {
            menuItems.push(
                <li key="legosAdmin" className="dropdown">
                    <a
                        href="#"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        Admin <span className="caret" />
                    </a>
                    <ul className="dropdown-menu">
                        <NavBarLink to="/appdefs/about">About</NavBarLink>
                        <NavBarLink to="/appdefs/logs">Logs</NavBarLink>
                        <li role="separator" className="divider" />
                        <NavBarLink to="/quest">Quests</NavBarLink>
                        <NavBarLink to="/resources/admin">Resources</NavBarLink>
                        <NavBarLink to="/shorturls">Short URLs</NavBarLink>
                        <li role="separator" className="divider" />
                        <NavBarLink to="/appdefs/admin/json-editor">App Settings</NavBarLink>
                    </ul>
                </li>
            );
        }

        menuItems.push(
            <li key="logout" className="dropdown">
                <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <IconUser spaceRight />
                    {Fortress.user.username} <i className="caret" />
                </a>
                <ul className="dropdown-menu">
                    {allowSwitchRoles &&
                        !Fortress.user.loggedInAsAnotherUser &&
                        switchRoles.map(pawn => (
                            <li key={pawn.id}>
                                <a href="#" onClick={e => changeRole(pawn.id, e)}>
                                    {pawn.name}
                                    {pawn.id === Fortress.user.pawnId && (
                                        <IconCheckmark spaceLeft />
                                    )}
                                </a>
                            </li>
                        ))}
                    {allowSwitchRoles && !Fortress.user.loggedInAsAnotherUser && (
                        <li role="separator" className="divider" />
                    )}
                    {!Fortress.user.loggedInAsAnotherUser &&
                        Fortress.user.user.auth_type !== 'ldap' && (
                            <>
                                <NavBarLink to="/account/profile">Update Profile</NavBarLink>
                                <li role="separator" className="divider" />
                                <NavBarLink to="/account/updatePassword">
                                    Change Password
                                </NavBarLink>
                                <li role="separator" className="divider" />
                            </>
                        )}

                    <li key="logoutLink">
                        <a href={Boilerplate.url('/api/user/logout')}>Log out</a>
                    </li>
                </ul>
            </li>
        );
    } else {
        menuItems.push(
            <li key="login">
                <a href={Boilerplate.url('/account/login')}>Log In</a>
            </li>
        );
    }

    return (
        <nav className="navbar navbar-default">
            <div className={className}>
                <div className="navbar-header">
                    <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#main-navbar-collapse"
                        aria-expanded="false"
                    >
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                        <span className="icon-bar" />
                    </button>
                    {renderLogo()}
                </div>

                <div className="collapse navbar-collapse" id="main-navbar-collapse">
                    <ul className="nav navbar-nav navbar-right">
                        {returnToUserLink}
                        {props.children}
                        {showMenu && menuItems}
                    </ul>
                </div>
            </div>
        </nav>
    );
}
