import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';

import { client } from 'cccisd-apollo';
import { Quest } from 'cccisd-laravel-assignment';
import Loader from 'cccisd-loader';
import { Redirect, useLocation } from 'cccisd-react-router';

import userActivationStatusQuery from './userActivationStatus.graphql';

// for users that got sent a /d/deployment_hash/user_hash link instead of the /orgAssessment one
const DeploymentRedirect = props => {
    const [userRoleAndActivationStatus, setUserRoleAndActivationStatus] = useState({});
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        setLoading(true);
        (async () => {
            const pathParts = location.pathname.split('/');
            let userHash = pathParts[pathParts.length - 1];
            let deploymentHash = pathParts[pathParts.length - 2];

            await getUserActivationStatus(userHash, deploymentHash);
            setLoading(false);
        })();
    }, [location]);

    const getUserActivationStatus = async (userHash, deploymentHash) => {
        const result = await client.query({
            query: userActivationStatusQuery,
            fetchPolicy: 'network-only',
            variables: {
                userHash,
                deploymentHash,
            },
        });

        const data = result.data.roles.anyRole;
        const isActivated = data.user.activated;
        const activationUrl = data.user.activationUrl;
        const role = data.pawn.role;
        const isOrgAssessment =
            _get(result, 'data.flows.deployment.assignment.assignmentHandle', false) === 'orgAssessment';

        setUserRoleAndActivationStatus({
            activationUrl,
            isActivated,
            isOrgAssessment,
            role,
        });
    };

    if (loading) {
        return <Loader />;
    }

    // if not activated yet, help them out by redirecting to activation URL
    if (
        ['orgAdmin', 'orgTeamMember'].includes(userRoleAndActivationStatus.role) &&
        !userRoleAndActivationStatus.isActivated
    ) {
        window.location = userRoleAndActivationStatus.activationUrl;
        return <Loader />;
    }

    // Nobody should ever access the Org Assessment with /d/.../... route
    // (regardless of whether they're logged in or not)
    if (userRoleAndActivationStatus.isOrgAssessment) {
        return <Redirect to="/orgAssessment" />;
    }

    return <Quest {...props} />;
};

export default DeploymentRedirect;
