import React from 'react';
import Header from '../../../../components/Header';

/*
 This component is used for the Quest header if "showHeader" is set to true
*/
export default () => {
    return (
        <div style={{ marginBottom: -22 }}>
            <Header />
        </div>
    );
};
